import React from 'react'
import { Link, navigate } from 'gatsby'
import { Match } from '@reach/router'
import PageHelmet from './helmet'
import '../css/index.scss'

const ListItemLink = ({ to, children }) => {
  return (
    <Match path={to}>
      {({ match }) => (
        <li className={match ? 'active' : ''}>
          <Link to={to}>{children}</Link>
        </li>
      )}
    </Match>
  )
}

const Layout = ({ withoutFooter, children }) => {
  const onChange = ({ target }) => {
    navigate(target.value)
  }

  return (
    <div className='main-wrap'>
      <PageHelmet pageTitle='Bytové družstvo Pod Kempou' />
      <header className='header'>
        <div className='container'>
          <div className='sixteen columns'>
            <div className='header-row'>
              <div className='header-col br/and' style={{ textAlign: 'center' }}>
                <a style={{ color: '#575757' }} href='/'>
                  <strong style={{ textTransform: 'uppercase' }}>
                    <span style={{ fontSize: '18px' }}>Bytové družstvo</span>
                    <br />
                    <span style={{ fontSize: '26px' }}>Pod Kempou</span>
                  </strong>
                </a>
              </div>
              <nav className='header-col navigation'>
                <ul className='l_tinynav1'>
                  <ListItemLink to='/'>Domů</ListItemLink>
                  <ListItemLink to='/posts'>Aktuality</ListItemLink>
                  <ListItemLink to='/records'>Zápisy</ListItemLink>
                  <ListItemLink to='/documents'>Dokumenty</ListItemLink>
                  <ListItemLink to='/contacts'>Kontakty</ListItemLink>
                </ul>
                <select id='tinynav1' className='tinynav tinynav1' onChange={onChange}>
                  <option>-- Menu --</option>
                  <option value='/'>Domů</option>
                  <option value='/posts'>Aktuality</option>
                  <option value='/records'>Zápisy</option>
                  <option value='/documents'>Dokumenty</option>
                  <option value='/contacts'>Kontakty</option>
                </select>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className='content'>{children}</div>

      {!withoutFooter && (
        <footer className='footer'>
          <div className='footer-top'>
            <div className='container'>
              <div className='one-third column'>
                <h4>Kontakt</h4>
                <p>
                  Bytové družstvo Pod Kempou
                  <br />
                  Okružní 1756/13
                  <br />
                  737 01 Český Těšín
                  <br />
                  <br />
                  IČO: 26830256
                </p>
              </div>
              <div className='one-third column'>
                <h4>Havarijní služby</h4>
                <p>
                  <strong>ČEZ, a.s.</strong>
                  <br />
                  zákaznická linka: 840 840 840
                  <br />
                  poruchy: 840 850 860
                  <br />
                  <br />
                  <strong>Severomoravské vodovody a kanalizace, a.s.</strong>
                  <br />
                  zákaznická linka: 840 111 123
                  <br />
                  poruchy: 840 111 125
                  <br />
                  <br />
                  <strong>Severomoravská plynárenská, a.s.</strong>
                  <br />
                  zákaznická linka: 840 111 115
                  <br />
                  poruchy: 1239
                  <br />
                </p>
              </div>
              <div className='one-third column'>
                <h4>Opravy</h4>
                <p>
                  <strong>Plyn - Gas systém</strong>
                  <br />
                  tel. 777 178 817
                  <br />
                  <br />
                  <strong>Elektřina - p. Niemiec</strong>
                  <br />
                  tel. 737 070 030
                  <br />
                  <br />
                  <strong>Voda, topení - p. Sebera</strong>
                  <br />
                  tel. 777 047 519
                  <br />
                  <br />
                  <strong>Plyn. sporáky p. Portysz</strong>
                  <br />
                  tel. 732 287 408
                </p>
              </div>
            </div>
          </div>

          <div className='footer-bottom'>
            <div className='container'>
              <div className='eight columns'>
                Copyright © 2014, All Rights Reserved. Created by <a href='http://iziart.cz'>www.iziart.cz</a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  )
}

export default Layout
