import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle}</title>
          <meta name='description' content='Bytové družstvo Pod Kempou' />
          <meta name='author' content='Rostislav Kulla' />
          <html lang='cs' />
        </Helmet>
      </React.Fragment>
    )
  }
}

export default PageHelmet
